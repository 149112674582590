.slides-container {
    width: 700px;
    height: 500px;
    margin: 0 auto;
}

.slide-container {
    width: 100%;
    height: 100%; 
    margin: 0 auto;
    position: relative;
}

.slide-image-overflow-container {
    overflow: hidden;
    height: 100%;
}

.slide-image-container {
    height: 100%;
    display: flex;
    transition: transform ease-out 0;
     
}

.slide-image {
    border-Radius: 10px;
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
}

@media screen and (max-width: 750px) {
    .slides-container {
        width: 90vw;
        height: 90vw;
        margin: 0 auto;
    }

    .slide-image {
        width: 90vw;
        border-Radius: 10px;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .slide-arrow {
        display: none;
    }
}

.slide-arrow {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 45px;
    color: rgba(255, 255, 255, 0.831);
    z-index: 1;
    cursor: pointer;
}

.slide-arrow:hover {
    transform: translate(0, -50%) scale(1.2);
}

.left-arrow {
    left: 2%;
}

.right-arrow {
    right: 2%;
}

.slide-dot-container {
    display: flex;
    justify-content: center;
}

.slide-dot {
    margin: 0 3px;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.831);
    cursor: pointer;
}

.slide-dot-bigger {
    margin: 0 3px;
    font-size: 25px;
    color: rgba(255, 255, 255, 0.831);
    cursor: pointer;
}

.slide-dot:hover {
    transform: scale(1.2);
}
