.recipe-card {
    position: relative;
    border-radius: 10px;
    width: 500px;
    height: 600px;
    /* background-color: whitesmoke; */
    /* background-color: #9D480F; */
    background-color: #F48D34;
    /* color: #F48D34; */
    -webkit-box-shadow: 1px 1px 12px #000;
    box-shadow: 1px 1px 12px #000;
    -webkit-transform: preserve-3d;
    -ms-transform: preserve-3d;
    transform: preserve-3d;
    -webkit-perspective: 2000px;
    perspective: 2000px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    /* color: #000; */

    margin: 50px 20px;
    flex-wrap: wrap;
  }
  
.recipe-card-image-container {
    background: url("./../../Media/Recipes/Babeelicious-card-background.png");
    background-size: cover;
    background-position: top;
    border-radius: 10px;

    width: 500px;
    height: 500px;
    position: absolute;
    top: 0;
    border-radius: 10px 10px 0 0;
}

.recipe-card-image {
    width: 100%;
    border-radius: 10px 10px 0 0;
}


.recipe-card h2 {
    font-size: 17px;
    font-weight: bolder;
    /* font-family: "pacifico"; */
    font-family: "poppins";
    color: #fff;
    position: absolute;
    bottom: 25px;
    background-color: #9D480F;
    border: #fff 3px solid;
    padding: 1.5% 2%;
    width: fit-content;
    margin-left: 10px;
    margin-right: 10px;
}

.recipe-card:hover, .recipe-card h2:hover {
    transform: scale(1.1);
}
