.dyk-main-container {
    display: inline-block;
    width: fit-content;
    margin: 20px;
}

.dyk-image-main-container {
    width: 500px;
    height: 500px;

    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 510px) {
    .dyk-main-container {
        display: inline-block;
        width: fit-content;
        margin: 20px auto;
    }

    .dyk-image-main-container {
        width: 90vw;
        height: 90vw;
    
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

.dyk-image-container {
    width: 100%;
    height: 100%; 
    margin: 0 auto;
    position: relative;

    display: flex;
    transition: transform ease-out 0;
}

.dyk-image-overflow-container {
    overflow: hidden;
    height: 100%;
}

.dyk-image {
    width: 100%;
    /* display: block; */
    border-radius: 5px;
}

.slide-arrow {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 45px;
    color: rgba(255, 255, 255, 0.831);
    z-index: 1;
    cursor: pointer;
}

.slide-arrow:hover {
    transform: translate(0, -50%) scale(1.2);
}

.left-arrow {
    left: 2%;
}

.right-arrow {
    right: 2%;
}


/* ///  DOTS  /// */

.slide-dot-container-dyk {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 20px auto;
    padding: 5px;
    width: fit-content;
    background-color: rgba(0, 0, 0, .5);
    border-radius: 10%;
}

/* .silde-dot-individual-container {
    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 50px;
    height: 50px;
} */

.slide-dot-dyk {
    /* margin: 0 10px; */
    font-size: 20px;
    color: rgba(255, 255, 255, 0.831);
    cursor: pointer;
}

.slide-dot-bigger-dyk {
    /* margin: 0; */
    font-size: 45px;
    color: rgba(255, 255, 255, 0.831);
    cursor: pointer;
}

.slide-dot-dyk:hover {
    transform: scale(1.2);
}

@media screen and (max-width: 510px) {
    .silde-dot-individual-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
    }

    .slide-dot-dyk {
        /* margin: 0 10px; */
        font-size: 10px;
        color: rgba(255, 255, 255, 0.831);
        cursor: pointer;
    }
    
    .slide-dot-bigger-dyk {
        /* margin: 0; */
        font-size: 30px;
        color: rgba(255, 255, 255, 0.831);
        cursor: pointer;
    }
}