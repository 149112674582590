.background-image-recipes-50 {
    background: url("./../../Components/Media/Background-50/kitchen-50.jpg");
    background-position: top;
    background-size: cover;
    width: 100%;
    min-height: 1262px;
    padding: 100px 0;
    background-repeat: repeat;
    z-index: 1;

    opacity: 0;
    animation: fadeInBackground .45s forwards;
}

@keyframes fadeInBackground {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}


.background-image-recipes-repeated {
  background: url("./../../Components/Media/Background-50/kitchen-50.jpg");
  background-size: contain;
  background-repeat: repeat;

  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  margin: 0 auto;

  opacity: 0;
  animation: fadeInBackgroundRepeated .45s forwards;
}

@keyframes fadeInBackgroundRepeated {
from {
    opacity: 0;
} 
to {
    opacity: 1;
}
}