.nav-div {
    margin: 0 !important;
}

.background-image-post-news {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: url("./../../../Components/Media/news.jpg");
    /* background-position: auto; */
    background-size: contain;
    background-repeat: repeat;

    text-align: center;

    opacity: 0;
    animation: fadeInBackgroundPostNews .45s forwards, opacityDownBackgroundPostNews 2s 1.6s forwards;
}

@keyframes fadeInBackgroundPostNews {
    from {
        opacity: 0;
    } 
    to {
        opacity: 1;
    }
}

@keyframes opacityDownBackgroundPostNews {
    from {
        opacity: 1;
    }
    to {
        opacity: .9;
        filter: brightness(40%);
    }
}


.post-container {
    z-index: 1;
    opacity: 0;
    width: 100%;
    animation: fadeInPostContainerNews 1.5s .7s forwards, colorPostContainerNews 2s 2.1s forwards; 
}

@keyframes fadeInPostContainerNews {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes colorPostContainerNews {
    from {
        color: inherit;
    }
    to {
        color: rgb(222, 222, 222);
    }
}


.post-title-content {
    text-align: center;
    max-width: 500px;
    margin: 0 auto 50px;

}

.post-top {
    text-align: center;
}

.post-news-title {
    font-family: 'Flatory', serif;
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 50px;
    display: inline-block;
    text-align: center;
    color: #FFD200;
    animation: colorPostTitleNews 2.5s 2s forwards;
}

@keyframes colorPostTitleNews {
    from {
        color: #FFD200;
    }
    to {
        color: rgb(222, 222, 222);
    }
}

.post-image-with-description-news {
    max-width: 500px;
    margin: 30px auto 0 !important;
}

.post-mascott {
    width: 112px; 
    height: 100%; 
    vertical-align: middle;
    position: inherit;
}

.post-content-news {
    font-family: "courier prime";
    /* color: #3C6E34 !important; */
    font-weight: 200;
    line-height: 1.5;
    font-size: 1.2rem;
   }

.post-content-news p {
    background-color: rgba(0, 0, 0, .7);
    color: rgb(222, 220, 220);
}

.post-content-news br {
    background-color: none;
}

.post-punchline {
    display: inline-block;
    vertical-align: top; 
    margin: 10px;

    background-color: rgba(222, 224, 227, .75);
    color: #000;
    font-style: italic;
    font-weight: 600;
    width: fit-content;
    max-width: 500px;
}

.post-author-date-news p {
    background-color: #FDA0A0;
    border: #FFD200 2px solid;
    color: #fff;
    font-family: "pacifico";
    max-width: 200px;
    margin: 15px auto;
}

