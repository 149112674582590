/* No min-height on screens < 768px */
.background-image-articles-all {
    background: url("./../../Components/Media/library.jpg");
    background-position: top;
    background-size: cover;
    background-repeat: repeat;
    width: 100%;
    min-height: 1262px;
    padding: 11vh 0;
    z-index: 1;

    opacity: 0;
    animation: fadeInBackground .45s forwards;
}

@keyframes fadeInBackground {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }


.background-image-articles-repeated {
    background: url("./../../Components/Media/library.jpg");
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    width: 100%;
    min-height: 1262px;
    padding: 11vh 0;
    position: absolute;

    opacity: 0;
    animation: fadeInBackgroundRepeated .45s forwards;
}

@keyframes fadeInBackgroundRepeated {
  from {
      opacity: 0;
  } 
  to {
      opacity: .7;
  }
}
  

/* ////////// CARD ///////////// */
.weaky-card-container {
  display: flex; 
  flex-wrap: wrap; 
  justify-content: center; 
  align-items: center;

  max-width: 950px;
  margin: 0 auto;
  
}

.weaky-card-container-dyk {
  /* display: flex; 
  flex-wrap: wrap; 
  justify-content: center; 
  align-items: center; */

  /* max-width: 950px; */
  width: 100%;
  margin: 0 auto;
  display: inline-block;
  
}

/* ///////////////////////////// */

.background-image-articles-50 {
  background: url("./../../Components/Media/rooms/rooms-opacity-50/library-opacity-50.jpg");
  background-position: top;
  background-size: cover;
  background-repeat: repeat;
  width: 100%;
  min-height: 1262px;
  padding: 100px 0;
  z-index: 1;

  opacity: 0;
  animation: fadeInBackground .45s forwards;
}


/* /// DYK /// */
.background-image-dyk {
  background: url("./../../Components/Media/beehive-opacity50.jpg");
  background-size:contain;
  background-repeat: repeat;

  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  margin: 0 auto;
  padding-top: 150px;

  opacity: 0;
  animation: fadeInBackgroundDYK .45s forwards;
}

@keyframes fadeInBackgroundDYK {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dyk-main-image {
  width: 700px;
  margin: 0 auto 20px;
}

@media screen and (max-width: 710px) {
  .dyk-main-image {
    width: 90vw;
    margin: 0 auto 20px;
  }
}