.background-image-winners {
    background: url("./../../Components/Media/contest.jpg");
    background-position: top;
    background-size: cover;
    width: 100%;
    min-height: 1262px;
    padding: 150px 0;
    background-repeat: no-repeat;
    position: absolute;
    margin: 0 auto;

    opacity: 0;
    animation: fadeInBackgroundWin .45s forwards, opacityDownBackgroundWin 2s 1.7s forwards;
}

@keyframes fadeInBackgroundWin {
    from {
        opacity: 0;
    } 
    to {
        opacity: 1;
    }
}


@keyframes opacityDownBackgroundWin {
    from {
        opacity: 1;
    }
    to {
        opacity: .70;
        filter: brightness(90%);
    }
}

.winners-container {
    width: 100%;
    /* top: 50%; */
    
    text-align: center;
    margin: 0 auto;
    padding-top: 100px;

    opacity: 0;
    animation: fadeInWinnersContainer 1.5s .7s forwards, colorPostContainer 2s 2.1s forwards;
}

@keyframes fadeInWinnersContainer {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes colorPostContainer {
    from {
        color: inherit;
    }
    to {
        color: rgb(222, 222, 222);
    }
}

.mascott-intro-winners {
    width: 243px;
    display: block;
    margin: 30px auto;
}

.logo-frame {
    width: 200px;
    display: inline-block;
    margin: 0 20px;
    border-style: ridge;
    border-color: aliceblue;
}

