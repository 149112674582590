.book-weaky {
    position: relative;
    border-radius: 0 10px 10px 0;
    width: 220px;
    height: 220px;

    --max-width: 90vw;
    --max-height: var(--max-width);

    max-width: var(--max-width);
    max-height: var(--max-height);
    margin: 15px;
    background-color: #000;
    -webkit-box-shadow: 1px 1px 12px #000;
    box-shadow: 1px 1px 12px #000;
    -webkit-transform: preserve-3d;
    -ms-transform: preserve-3d;
    transform: preserve-3d;
    -webkit-perspective: 2000px;
    perspective: 2000px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #000;
    font-family: "eb garamond";
  }
  
  .cover-weaky {
    top: 0;
    position: absolute;
    /* background-color: #3E7634; */
    background-color: #dedede;
    width: 100%;
    height: 100%;
    border-radius: 0 10px 10px 0;
    cursor: pointer;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transform-origin: 0;
    -ms-transform-origin: 0;
    transform-origin: 0;
    -webkit-box-shadow: 1px 1px 12px #000;
    box-shadow: 1px 1px 12px #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    
    font-family: "eb garamond";
    z-index: 3;
  }

.weaky-cover-top {
    background-color: #000;
    border-radius: 0 10px 0 0;
    height: 80%;
    width: 100%;
    position: absolute;
    top: 0;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 55% 80%, 50% 90%, 45% 80%, 0 90%);
}
  
  .book-weaky:hover .cover-weaky {
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transform: rotatey(-88deg);
    -ms-transform: rotatey(-88deg);
    transform: rotatey(-88deg);
  }


.weaky-cover-heading {
  font-size: 1.5rem;
  font-weight: 700;
  /* color: #FFB800; */
  color: #dedede;
  margin: 3.5rem 2px 0;
}

.weaky-cover-icon {
  position: absolute;
  bottom: 7px;
  width: 50px;
  height: 50px;
}


.weaky-book-image {
  width: 100%;
  height: 100%;
  border-radius: 0 10px 10px 0;
  z-index: 1;
  opacity: 1;
  /* filter: brightness(40%); */
}

.low-brightness {
  filter: brightness(40%);
}

.weaky-book-inside {
  width: 100%;
  height: 100%;
  z-index: 2;
  background: transparent;
  position: absolute;
}

.weaky-book-inside-container {
  position: relative;
  margin-top: 1.5rem;
  height: fit-content;
}

.weaky-cover-inside-heading {
  font-size: 1.33rem;
  font-weight: 800;
  /* color: #FFB800; */
  color: #3E7634;
}

.weaky-cover-inside-description {
  /* color: #FFB800; */
  color: #dedede;
  font-size: 80%;
  font-weight: 700;
  margin-top: .5rem;
}

@media screen and (max-width: 230px) {
  .weaky-cover-inside-description {
    opacity: 0;
  }

  .weaky-honeycomb {
    font-size: 1%;
  }

  .weaky-honeycomb:hover {
    background-color: white;
  }

}

.weaky-honeycomb {
  width: 40%; /* Adjust according to your needs */
  height: 15%; /* Adjust according to your needs */
  background-color: rgba(60, 116, 52, .9); /* Or any other color you prefer */
  clip-path: polygon(
      10% 0,
      90% 0,
      100% 50%,
      90% 100%,
      10% 100%,
      0% 50%
    );

    font-weight: 600;

  padding-top: .27rem;
  position: absolute;
  bottom: 18px;
  left: 31%;
}

.weaky-honeycomb:hover {
  background-color: rgba(60, 116, 52, 1);
  transform: scale(1.2rem);
  color: white;
}
  