.background-image-hive-of-fame {
    background: url("./../../Components/Media/hive-of-fame.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: repeat;

    width: 100%;
    height: 0;
    padding-top: 100%;
    position: absolute;
    margin: 0 auto;

    opacity: 0;
    animation: fadeInBackgroundFame .45s forwards, opacityDownBackgroundFame 2s 1.6s forwards;
}

@keyframes fadeInBackgroundFame {
    from {
        opacity: 0;
    } 
    to {
        opacity: 1;
    }
}

@keyframes opacityDownBackgroundFame {
    from {
        opacity: 1;
    }
    to {
        opacity: .9;
        filter: brightness(40%);
    }
}

.winners-container {
    width: 100%;
    /* top: 50%; */
    
    text-align: center;
    margin: 0 auto 100px;

    opacity: 0;
    animation: fadeInWinnersContainer 1.5s .7s forwards, colorPostContainer 2s 2.1s forwards;
}

@keyframes fadeInWinnersContainer {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes colorPostContainer {
    from {
        color: inherit;
    }
    to {
        color: rgb(222, 222, 222);
    }
}

.mascott-intro-winners {
    width: 200px;
    display: block;
    margin: 30px auto;
}

.logo-frame {
    width: 200px;
    display: inline-block;
    margin: 0 20px;
    border-style: ridge;
    border-color: aliceblue;
}

