.App {
  text-align: center;
  background-color: #000;
}


body {
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer; 
}

a:hover {
  color: #e3e5e9;
}

.Link, .NavLink {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.link, .navLink {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.cover-container {
  display: flex; 
  flex-wrap: wrap; 
  justify-content: center; 
  align-items: center;

  max-width: 950px;
  margin: 0 auto;
}

.mascott-message {
  background-color: rgba(222, 224, 227, .75);
    color: rgba(0, 0, 0, .9);
    font-style: italic;
    font-weight: 600;
    width: fit-content;
    /* padding: 10px; */
    border-radius: 35%;
    padding: 3%;
    margin: 20px auto 0;
}

.logo-title {
  width: 400px;
  display: block;
  margin: 10px auto;
}

.logo-title-xl {
  width: 500px;
  display: block;
  margin: 10px auto;
}

.mascott-title {
  height: 200px;
  display: block;
  margin: 10px auto;
}

.mascott-title-activity {
  width: 300px;
  display: block;
  margin: 10px auto;
}

.mascott-punchline-container {
  /* width: 500px; */
}

.mascott-punchline {
  height: 200px;
  display: inline-block;
}

.logo-animation {
  width: 500px;
  display: block;
  margin: 0 auto;
}

.mascott-animation {
  width: 200px;
  display: block;
  margin: 0 auto;
}

.mascott-animation2 {
  width: 250px;
  display: block;
  margin: 0 auto;
}

@media screen and (max-width:750px) {

  .mascott-message {
    padding: 13%;
  }

}

@media screen and (max-width: 500px) {
  .logo-title {
    width: 90vw;
    display: block;
    margin: 10px auto;
  }

  .logo-title-xl {
    width: 90vw;
    display: block;
    margin: 10px auto;
  }

  .mascott-title-activity {
    width: 200px;
    display: block;
    margin: 10px auto;
  }

  .logo-animation {
    width: 90vw;
    display: block;
    margin: 0 auto;
  }

  .post-content img {
    max-width: 90vw;
  }

}



.cover-container, .logo-title {
  opacity: 0;
  animation: fadeInContainer 2s forwards;
  /* animation-delay: 4s; */
}

@keyframes fadeInContainer {
  from {
    opacity: 0; /* Start with opacity 0 */
  }
  to {
    opacity: 1; /* End with opacity 1 */
  }
}


.logonimation {

  opacity: 0;
  animation: fadeInLogo 2.6s .5s forwards, fadeOutLogo 2.3s 2.5s forwards;
}

@keyframes fadeInLogo {
  from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

@keyframes fadeOutLogo {
  from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
}

/* ///////// BUTTON MAIN ////////// */

.button-main {
  display: inline-block;
  font-weight: 500;
  background-color: rgba(255, 215, 0, 1);
  text-align: center;
  vertical-align: middle;
  user-select: none;
  /* background-color: transparent; */
  border: none;
  margin: 0 10px;
  padding: 10px 0.75rem;
  font-size: 1rem;
  /* line-height: 1.5; */
  border-radius: 35%;
  text-decoration: none;
  /* transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */
}

.button-main:hover {
  /* font-weight: 600; */
  text-decoration: none;
  transform: scale(1.1);
  font-weight: 700;
}

.button-main:focus,.button-main.focus {
  /* outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); */
  box-shadow: none;
  outline: none;
  border: none;
}

/* .button-main.disabled,.button-main:disabled {
  opacity: 0.65;
} */

.button-main:not(:disabled):not(.disabled) {
  cursor: pointer;
}
/* ////////////// ADS ////////////////// */

.beeXShopAD {
  max-width: 300px;
  width: 35%;
  min-width: 140px;
  display: inline-block;
  margin: 20px auto 30px;
}


/* //////////// LEGALS ///////////*/

.legals {
  padding: 70px 20vw 20px;
}

.legals h2 {
  color: rgba(255, 215, 0, 1);
}

.legals p {
  color: rgb(48, 221, 25)
}

.legals a {
  color: rgb(60, 194, 247);
}

/* ///////// */

.image-description {
  /* position: absolute;
  top: 50%; */
  margin: 0 auto 30px;
  font-size: .7rem;
  font-weight: 600;
}

.image-description a {
  color: azure;
}

.image-description a:hover {
  color: rgb(9, 238, 66);
}

/* /////// Mouse //////// */

.wait-cursor {
  cursor: wait;
}

/* .sub-button:hover {
  cursor: wait;
} */