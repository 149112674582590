.quiz-container {
    margin: 70px auto;
    border: 5px dotted black;
    /* border-bottom: none; */
    max-width: 800px;
    padding: 0 50px;
}

.quiz-container h1 {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
    background-color: rgba(255, 193, 7, 1);
    width: fit-content;
    margin: 30px auto 50px;
    padding: 5px;
    border-radius: 5px;
} 
/* rgba(40, 167, 69, .7); */

.quiz-choices-buttons-container {
    /* display: inline-block; */
    text-align: left;
}

.quiz-choices-container {
    position: relative; 
    background-color: #dedede; 
    color: #000;
    width: fit-content;
    padding: 0 10px;
}

.quiz-choices {
    text-align: left;
    margin: 20px 0;
    font-family: "Arial";
}

.quiz-button-container {
    text-align: left;
}

.quiz-button {
    display: inline-block;
    margin: 5px 5px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.btn-hint {
    color: black;
}

.btn-answer {
    color: white;
}

.btn-explanation {
    color: white;
}


.quiz-answers-container {
    text-align: center;
    margin: 30px auto;

}

.quiz-hint {
    font-size: 1.3rem;
    background-color: rgba(255, 193, 7, 1);
    color: #000;
    width: fit-content;
    margin: 0 auto;
    padding: 5px;
    border-radius: 5px;
}

.quiz-answer {
    font-size: 1.3rem;
    background-color: rgba(40, 167, 69, .7);
    color: whitesmoke;
    width: fit-content;
    margin: 10px auto;
    padding: 5px;
    border-radius: 5px;
}

.quiz-explanation {
    font-size: 1.1rem;
    background-color: rgba(0, 123, 255, .7);
    color: whitesmoke;
    width: fit-content;
    margin: 10px auto;
    padding: 5px;
    border-radius: 5px;
}

.explanation img {
    max-width: 200px;
    margin: 20px;
    border-radius: 5px;
}