.nav-div {
    margin: 0 !important;
}

.background-image {
    background: url("./../../../Components/Media/libraryQueen.jpg");
    background-size: contain;
    background-repeat: repeat;

    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    margin: 0 auto;

    opacity: 0;
    animation: fadeInBackgroundPost .45s forwards, opacityDownBackgroundPost 2s 1.6s forwards;
}

@keyframes fadeInBackgroundPost {
    from {
        opacity: 0;
    } 
    to {
        opacity: 1;
    }
}

@keyframes opacityDownBackgroundPost {
    from {
        opacity: 1;
    }
    to {
        opacity: .9;
        filter: brightness(40%);
    }
}


.post-container-weaky {
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 70px 0;

    opacity: 0;
    animation: fadeInPostContainer 1.5s .7s forwards, colorPostContainerWeaky 2s 2.1s forwards; 
}

@keyframes fadeInPostContainer {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* @keyframes colorPostContainerWeaky {
    from {
        color: inherit;
    }
    to {
        color: rgb(222, 222, 222);
    }
} */


.post-title-content {
    text-align: center;
    max-width: 500px;
    margin: 0 auto 50px;

}

.post-top {
    text-align: center;
}

.post-title {
    font-size: 3rem;
    font-family: "eb garamond";
    margin-bottom: 50px;
    display: inline-block;
    text-align: center;
    color: #3E7634;
    animation: colorPostTitle 2.5s 2s forwards;
}

@keyframes colorPostTitle {
    from {
        color: #3E7634;
    }
    to {
        color: rgb(222, 222, 222);
    }
}

.post-mascott {
    width: 130px; 
    height: 100%; 
    vertical-align: middle;
    position: inherit;
}

.post-mascott-explanation {
    width: 50px; 
    vertical-align: middle;
    position: inherit;
}

.post-content {
    font-family: "eb garamond";
    /* color: #3C6E34 !important; */
    font-weight: 200;
    line-height: 1.8;
    font-size: 1.3rem;
   }

.post-content p {
    background-color: rgba(0, 0, 0, .7);
    color: rgb(222, 220, 220);
}

.post-content a {
    color: rgb(222, 220, 220);
}

.post-anchor {
    color: rgba(255, 193, 7, .9) !important;
}

.post-anchor:hover {
    color: rgba(40, 167, 69, 1) !important;
}

.post-content h2,
.post-content h3 {
    color: rgb(222, 220, 220);
}

.post-content img {
    width: 400px;
    margin: 30px auto;
    border-radius: 2%;
}

.post-image-with-description {
    max-width: 400px;
    margin: 30px auto 0 !important;
}

.post-content iframe {
    margin: 30px auto;
}

.post-video-with-description {
    margin: 30px auto 0 !important;
}

.post-punchline {
    display: inline-block;
    vertical-align: top; 
    margin: 10px;

    background-color: rgba(222, 224, 227, .75);
    color: rgba(0, 0, 0, .9);
    font-style: italic;
    font-weight: 600;
    width: fit-content;
    max-width: 500px;
}

.post-author-date p {
    background-color: #3E7634;
    border: #dedede 2px solid;
    color: #dedede;
    font-family: "pacifico";
    max-width: 200px;
    margin: 15px auto;
}

.post-content-title {
    font-size: 1.2rem;
}



