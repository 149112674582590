/* No min-height on screens < 768px */
.background-image-contest-all {
    background: url("./../../Components/Media/contest.jpg");
    background-position: top;
    background-size: cover;
    background-repeat: repeat;
    width: 100%;
    min-height: 1262px;
    padding: 100px 0;
    z-index: 1;

    opacity: 0;
    animation: fadeInBackground .45s forwards;
}

@keyframes fadeInBackground {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

.mascott-animation-contest {
    width: 243px;
    height: 200px;
    display: block;
    margin: 0 auto;
}

.logo-title-contest {
    width: 26.1vw;
    min-width: 100px;
    display: block;
    margin: 10px auto;
}

.mascott-title-contest {
    width: 122px;
    height: 100px;
    display: block;
    margin: 10px auto;
}

/* .background-image-contest-all {
    background: url("./../../Components/Media/contest.jpg");
    background-position: top;
    background-size: cover;
    width: 100%;
    min-height: 1262px;
    padding: 100px;
    background-repeat: repeat;
    z-index: 1;
} */