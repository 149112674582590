.background-image-beehive {
    background: url("./../../Components/Media/beehive.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: repeat;

    width: 100%;
    height: 0;
    padding-top: 100%;
    position: absolute;
    margin: 0 auto;

    opacity: 0;
    animation: fadeInBackgroundBeehive .45s forwards;
}

@keyframes fadeInBackgroundBeehive {
    from {
      opacity: 0;
    }
    to {
      opacity: .5;
    }
  }


.logonimation {

    opacity: 0;
    animation: fadeInLogo 2.6s .5s forwards, fadeOutLogo 2.3s 2.5s forwards;
}

@keyframes fadeInLogo {
    from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
}

@keyframes fadeOutLogo {
    from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
}

.beehive-title-container {
  width: fit-content;
  margin: 0 auto;
}

.beehive-title {
  color: rgba(255, 215, 0, .9);
  background-color: rgba(0, 0, 0, .7);
  padding: 5px;
  border-radius: 20%;
}

.beehive-para {
  color: rgba(255, 215, 0, .7);
  font-weight: 700;
}

.beehive-container {
    z-index: 100;
    margin-top: 50px;
}

.beehive-animation {
  width: 40%;
  display: block;
  margin: 0 auto;
  margin-top: 50px;
}


.cover-container {
    display: flex; 
    flex-wrap: wrap; 
    justify-content: center; 
    align-items: center;

    max-width: 950px;
    margin: 0 auto;

    
}

@keyframes fadeInContainer {
    from {
      opacity: 0; /* Start with opacity 0 */
    }
    to {
      opacity: 1; /* End with opacity 1 */
    }
  }


.background-image-all {
    background: url("./../../Components/Media/library.jpg");
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 1262px;
    background-repeat: repeat;
}


  