.bb-main-container {
    width: 200px;
    height: 200px;
    margin: 30px auto;
    overflow: hidden;

    --max-width: 90vw;
    --max-height: var(--max-width);

    max-width: var(--max-width);
    max-height: var(--max-height);
}

.bb-container {
    width: 100%;
    height: 100%;
    margin: 0 auto;

    background-size: cover;

    border-radius: 10px;
    opacity: .85;
}

.bb-container-para {
    display: flex;
    align-items: end;
    justify-content: center;

    width: 100%;
    height: 100%;
}

.bb-container-para p {
    font-weight: 700;
    font-size: 1.15rem;
    display: block;
    color: white;
    background-color: rgba(0, 0, 0, .3);
    padding: 5px 2px;
    width: 100%;
    
}

.bb-container-para:hover {
    transform: scale(1.05);
}