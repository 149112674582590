.background-image-postRecipes {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: url("./../../../Components/Media/kitchen-post.jpg");
    /* background-position: auto; */
    background-size: contain;
    background-repeat: repeat;

    text-align: center;

    z-index: 0;
    opacity: 0;
    animation: fadeInBackgroundPost .45s forwards, opacityDownBackgroundPostRecipes 2s 1.6s forwards;
}

@keyframes fadeInBackgroundPost {
    from {
        opacity: 0;
    } 
    to {
        opacity: 1;
    }
}

@keyframes opacityDownBackgroundPostRecipes {
    from {
        opacity: 1;
    }
    to {
        opacity: .9;
        filter: brightness(60%);
    }
}


.post-container {
    position: relative;
    margin: 0 auto 20px;
    padding: 70px 0 20px;
    z-index: 1;
    opacity: 0;
    animation: fadeInPostContainer 1.5s .7s forwards, colorPostContainer 2s 2.1s forwards; 
}

@keyframes fadeInPostContainer {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes colorPostContainer {
    from {
        color: inherit;
    }
    to {
        color: rgb(191, 189, 189);
    }
}


.post-title-content {
    text-align: center;
    max-width: 600px;
    margin: 0 auto 50px;

}

.post-title-recipes {
    font-size: 3rem;
    font-family: "pacifico";
    margin-bottom: 50px;
    display: inline-block;
    text-align: center;
    color: #F48D34;
    animation: colorPostTitleRecipes 2.5s 2s forwards;
}

@keyframes colorPostTitleRecipes {
    from {
        color: #F48D34;
    }
    to {
        color: rgb(222, 222, 222);
    }
}

.post-mainImage-recipes {
    max-width: 600px;
    margin: 0 auto 30px;
    border-radius: 5%;
}

.post-lastImage-recipes {
    max-width: 600px;
    margin: 0 auto 30px;
    border-radius: 5%;
}

@media screen and (max-width: 600px) {
    .post-container {
        width: 95vw;
    }

    .post-mainImage-recipes {
        width: 90vw;
        margin: 0 auto 30px;
        border-radius: 5%;
    }

    .post-lastImage-recipes {
        width: 90vw;
        margin: 0 auto 30px;
        border-radius: 5%;
    }
}

.post-content-recipes {
    /* font-family: "pacifico"; */
    font-family: "poppins";
    /* color: #3C6E34 !important; */
    font-weight: 500;
    line-height: 1.6;
    font-size: 1.2rem;
    letter-spacing: 3px;
}

.post-content-recipes p {
    /* background-color: rgba(0, 0, 0, .7); */
    color: rgb(241, 237, 237);
}

.post-content-recipes br {
    background-color: none;
}

.post-content-recipes img {
    width: 100%;
    border-radius: 10px;
}

.post-punchline {
    display: inline-block; 
    font-style: italic;
    vertical-align: top; 
    margin: 10px;
    background-color: rgba(0, 0, 0, .8);
    color: whitesmoke;
}

.post-author-date-recipes p {
    /* background-color: rgba(0, 0, 0, .5); */
    background-color: #9D480F;
    border: #fff 1px solid;
    color: whitesmoke;
    font-family: 'pacifico';
    max-width: 200px;
    margin: 15px auto;
}

.serving-time {
    background-color: rgba(244, 141, 52, .8);
    color: white;
    /* color: #9D480F; */
    /* border: #fff 3px solid; */
    border-radius: 3px;
    padding: 0 1%;
    margin: 0 auto;
    width: fit-content !important;
    font-size: 1.2rem;
}

.serving-time-key {
    font-weight: 700;
}

.ingredients {
    background-color: #9D480F;
    color: #F48D34;
    border: #fff 3px solid;
    padding: 2% 2% 0;
    border-radius: 3px;
    
}

.ingredients-title {
    font-size: 1.2rem;
    background-color: #F48D34;
    border: #fff 3px solid;
    padding: 1% 7%;
    width: fit-content;
    margin: 0 auto;
}

.ingredients-list {
    text-align: left !important;
    margin-left: 10px;
}

.directions {
    background-color: #F48D34;
    padding: 2% 0 0;
    border: #fff 3px solid;
    border-radius: 3px;
}

.directions p {
    padding: 3%;
}

.directions img {
    width: 100%;
    padding: 0 !important;
    margin: 0 auto 30px;
    border-radius: 0 !important;
}

.directions-last-img {
    margin: 0 auto !important; 
}

.directions-title {
    font-size: 1.2rem;
    background-color: #9D480F;
    border: #fff 3px solid;
    padding: 1% 7%;
    width: fit-content;
    margin: 0 auto;
}

