.recipes-card {
    position: relative;
    border-radius: 10px;
    max-width: 250px;
    background-color: whitesmoke;
    -webkit-box-shadow: 1px 1px 12px #000;
    box-shadow: 1px 1px 12px #000;
    -webkit-transform: preserve-3d;
    -ms-transform: preserve-3d;
    transform: preserve-3d;
    -webkit-perspective: 2000px;
    perspective: 2000px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #000;

    margin: 0 50px 50px;

    /* background: url("./../../Media/Recipes/recipes-card-with-babee.png");
    background-size: cover;
    background-position: top; */
}

.recipes-card img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
  