
.nav-div {
    width: 100%;
    background-color: #ffc107;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
}

.dropped-menu {
    background-color: #ffc107;
}

.navbar-explorer {
    display: flex; /* Use flexbox to align items */
    justify-content: space-between; /* Distribute items along the main axis with space between */
    align-items: center; /* Center items along the cross axis */
    position: fixed;
    top: 0;
    background-color: #ffc107;
    width: 99vw;
    padding: inherit 20px;
    z-index: 100;
}

.navbar-explorer a:hover {
    transform: scale(1.1);
}

.logo-nav-explorer {
    width: fit-content;
    height: fit-content;
    background-color: #000;
    border-radius: 25%;
    margin: 5px;
}

.logo-nav {
    width: 100px;
    height: 25px;
    margin-top: 5px;
    cursor: pointer;
}

.menu-nav-explorer {
    flex: 1;
}

.far-right-nav-explorer {
    padding-right: 15px;
}

.drop-toggle {
    background-color: rgb(46, 165, 30) !important;
    /* background-color: #0dc039 !important; */
}

ul {
    list-style-type: none;
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
}

.main-li-nav {
    display: inline-block;
    /* margin: 0 7px; */
}


.active2:active {
    /* background-color: rgb(39, 157, 23) !important; */
    background-color: rgb(46, 165, 30) !important;
    /* background-color: #0dc039 !important; */
}

.nav-pills .active {
    /* background-color: rgb(39, 157, 23) !important; */
    background-color: rgb(46, 165, 30) !important;
    /* background-color: #0dc039 !important; */
    font-weight: 600;
}

.nav-a {
    margin: 0 10px;
    display: inline-block;
}
