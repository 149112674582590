.articles-container {
    display: flex; 
    flex-wrap: wrap; 
    justify-content: center; 
    align-items: center;
  
    max-width: 1500px;
    margin: 0 auto;
    font-size: 0;
    position: relative;
      
}

.nav-link {
    align-items: center;
    display: flex;
    flex-direction: column;
}