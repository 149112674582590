.background-image-recipes {
    background: url("./../../Components/Media/kitchen.jpg");
    background-position: top;
    background-size: cover;
    width: 100%;
    min-height: 1262px;
    padding: 100px 0;
    background-repeat: repeat;
    z-index: 1;

    opacity: 0;
    animation: fadeInBackground .45s forwards;
}

@keyframes fadeInBackground {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }


.logonimation {

    opacity: 0;
    animation: fadeInLogo 2.6s .5s forwards, fadeOutLogo 2.3s 2.5s forwards;
}

@keyframes fadeInLogo {
    from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
}

@keyframes fadeOutLogo {
    from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
}






.background-image-recipes-all {
    background: url("./../../Components/Media/kitchen.jpg");
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 1262px;
    background-repeat: repeat;
}
  