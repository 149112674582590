.newspaper-container {
  position: relative;
}

.card-news-arrow{
  width: 70px;
  opacity: 0;
  display: inline-block;
}

.card-news-para {
  color: #000;
  display: inline-block;
  opacity: 0;
}

.newspaper-container:hover .card-news-para, .card-news-arrow {
  opacity: 1;
}

.newspaper {
    position: relative;
    border-radius: 10px;
    max-width: 220px;
    flex: 1 1 auto;
    margin: 20px;
    background-color: whitesmoke;
    -webkit-box-shadow: 1px 1px 12px #000;
    box-shadow: 1px 1px 12px #000;
    -webkit-transform: preserve-3d;
    -ms-transform: preserve-3d;
    transform: preserve-3d;
    -webkit-perspective: 2000px;
    perspective: 2000px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #000;

    background: url("./../../Media/BBS-news/BBS-newspaper-inside-background.png");
    background-size: cover;
    background-position: top;
  }
  
  .cover {
    top: 0;
    position: absolute;
    background-color: lightgray;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    cursor: pointer;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transform-origin: 0;
    -ms-transform-origin: 0;
    transform-origin: 0;
    -webkit-box-shadow: 1px 1px 12px #000;
    box-shadow: 1px 1px 12px #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  .newspaper:hover .cover {
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transform: rotatey(-83.7deg);
    -ms-transform: rotatey(-83.7deg);
    transform: rotatey(-83.7deg);
  }
  
.card-news-para {
    font-size: 20px;
    font-weight: bolder;
  }

.newspaper-image {
  width: 100%;
  height: 100%;
}