.background-image-donation {
    background: url("./../Components/Media/lavender-field.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: repeat;

    width: 100%;
    height: 0;
    padding-top: 100%;
    position: absolute;
    margin: 0 auto;

    opacity: 0;
    animation: fadeInBackgroundDonation .45s forwards;
}

@keyframes fadeInBackgroundDonation {
    from {
      opacity: 0;
    }
    to {
      opacity: .85;
    }
  }


  .background-image-donation-filling {
    background: url("./../Components/Media/Background-50/grey-background.png");
    background-position: center;
    background-size: cover;
    background-repeat: repeat;

    width: fit-content;
    height: 100%;
    /* position: absolute; */
    margin: 0 auto;

    opacity: 1;
}


  .beeMelanie-container {
    opacity: 0;
    animation: beeMelanieFadeIn 2s 1.3s forwards; 
  }

  @keyframes beeMelanieFadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: .8;
    }
  }


  .donation-container {
    position: relative;
    padding-top: 50px;

  }
  
.donation-container h1 {
  color: rgba(0, 0, 0, .75);
  background-color: rgba(255, 215, 0, .8);
  margin: 20px auto;
  padding: 10%;
  border-radius: 50%;
}

/* .donation-container h3 {
  color: rgba(0, 0, 0, .75);
  background-color: rgba(255, 215, 0, .8);
  margin: 20px auto;
  padding: 15px;
  border-radius: 50%;
} */

.donation-container h3 {
  color: rgba(0, 0, 0, .8);
  background-color: rgba(0, 0, 0, .2);
  width: fit-content;
  margin: 0 auto;
  padding: 1%;
  border-radius: 5.5%;
}

.donation-container p {
  color: rgba(0, 0, 0, .85);
  background-color: rgba(222, 224, 227, .85);
  font-weight: 500;
  margin: 20px auto 20px;
  padding: 10px;
  border-radius: 5%;
  max-width: 500px;
}

.beeMelanie {
  max-width: 27.73%;
  margin: 70px auto;

  border: 2px;
  border-style: solid;
  border-radius: 5%;
  /* border-color: rgba(255, 215, 0, .7); */
  border-color: rgba(240, 248, 255, .7);
}

.badges-donation img{
  width: 20%;
  display: inline-block;
  margin: 20px;
}

.mascots-donation {
  width: fit-content;
  margin: 20px;
  background: url("./../Components/Media/Background/grey-background.png") cover center;
}
