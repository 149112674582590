.cover-card {
    position: relative;
    max-width: 200px;
    padding-top: 45px;
    margin: 20px;
    display: inline-block;
    box-shadow: 0 4px 8px rgba(255, 193, 7, 1);
    border-radius: 15%;
    background-color: #0c0d0f;
    transition: transform .3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.cover-card:hover {
    transform: scale(1.05);
    box-shadow: 3px 3px 3px rgba(255, 193, 7, 1);
    width: fit-content;
    height: fit-content;
    min-width: 200px;;
    min-height: 220px;
    z-index: 100;
}

.cover-card:hover .cover-title {
    /* font-size: 1.3rem; */
    overflow: hidden;
    text-overflow: inherit;
    white-space: nowrap;
}


.cover-image {
    width: 75%;
    border-radius: 5%;
    margin: auto;
    transition: transform 0.3s ease-in-out;
    
}

.card:hover .cover-image {
    animation: shake 1s 3s ease-in-out forwards;
}

@keyframes shake {
    0%, 100% {
        transform: translateX(0);
    }
    25%, 75% {
        transform: translateX(5px);
    }
    50% {
        transform: translateX(-5px);
    }
}


.cover-title {
    font-family: 'sisterhood';
    margin: 20px 10px;
    color: #FFD700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
    font-weight: 700;
    /* font-size: 1.3rem; */
    transition: font-size 0.3s ease-in-out;
}


.date {
    color:rgba(255, 215, 0, .7);
    font-size: 0.8rem;
}