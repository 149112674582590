.article-card {
    position: relative;
    height: 220px;
    width: 200px;
    padding-top: 50px;
    margin: 20px;
    display: inline-block;
    box-shadow: 0 4px 8px rgba(255, 193, 7, 1);
    border-radius: 15%;
    background-color: #0c0d0f;
    transition: transform .3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.article-card:hover {
    transform: scale(1.05);
    box-shadow: 10px 10px 10px rgba(255, 193, 7, 1);
    width: fit-content;
    height: fit-content;
    min-width: 200px;;
    min-height: 220px;
    z-index: 100;
}

.article-card:hover .article-card-title {
    /* font-size: 1.3rem; */
    overflow: visible;
    text-overflow: inherit;
    white-space: nowrap;
}


.article-card-image {
    width: 150px;
    border-radius: 5%;
    transition: transform 0.3s ease-in-out;
}

.article-card:hover .article-card-image {
    animation: shake 1s 3s ease-in-out forwards;
}

@keyframes shake {
    0%, 100% {
        transform: translateX(0);
    }
    25%, 75% {
        transform: translateX(5px);
    }
    50% {
        transform: translateX(-5px);
    }
}


.article-card-title {
    margin: 20px 10px;
    color: #FFD700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: font-size 0.3s ease-in-out;
}


.article-card-date {
    color:rgba(255, 215, 0, .7);
    font-size: 0.8rem;
}