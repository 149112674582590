.background-image-news {
    background: url("./../../Components/Media/news.jpg");
    background-position: center;
    background-size: cover;
    width: 100%;
    min-height: 1262px;
    padding: 175px 0;
    background-repeat: repeat;
    z-index: 1;

    opacity: 0;
    animation: fadeInBackground .45s forwards;
}

@keyframes fadeInBackground {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}


.background-image-news-repeated {
  background: url("./../../Components/Media/BBS-news/BBS-news-earth.jpg");
  background-size: contain;
  background-repeat: repeat;

  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  margin: 0 auto;

  opacity: 0;
  animation: fadeInBackgroundNewsRepeated .45s forwards;
}

@keyframes fadeInBackgroundNewsRepeated {
from {
    opacity: 0;
} 
to {
    opacity: .7;
}
}


.news-card-container {
  display: flex; 
  flex-wrap: wrap; 
  justify-content: center; 
  align-items: center;

  margin: 0 auto;
  
}

