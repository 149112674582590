.beehive-home {
    width: 40%;
    margin: 5px auto 10px;
    border-radius: 2%;
}

.logo-home-container {
    width: 100%;
    background-color: rgba(255, 215, 0, .3);
    border-radius: 50%;
    padding: .5% 0;
    margin: 30px 0 100px;
}

.logo-home {
    width: 50%;
}

/* //////////////////////Welcome News //////////////////*/
.welcome-news-feed {
    width: fit-content;
    margin: 10px auto;
    white-space: nowrap;
}

.mascot-welcome-container {
    display: inline-block;
    width: fit-content;
    margin: 0 30px;
}

.welcome-message-home {
    font-style: italic;
    font-weight: 600;
    background-color: rgba(222, 224, 227, .75);
    color: #000;
    width: fit-content;
    /* padding: 10px; */
    margin: 0 auto;
}

.mascot-welcome {
    width: 200px;
    display: inline-block;
}

.mascot-welcome-lg {
    width: 300px;
    display: inline-block;
}

@media screen and (max-width: 500px) {
    .mascot-welcome {
        width: 30vw;
        display: inline-block;
    }
    
    .mascot-welcome-lg {
        width: 55vw;
        display: inline-block;
    }
}

.slide-show-home-container {
    width: fit-content;
    display: inline-block;
}



/* //////////////////////////////////////////////////////////////////// */


/* ///////////////// EXPLORE - PRESENTATIONS //////////////// */


.explore-container-title h1 {
    font-size: 3rem;
    color: rgba(222, 224, 227, .75);
    /* margin: 50px auto; */
    
}

.explore-container-title {
    width: 100%;
    background-color: rgba(60, 116, 52, .4);
    border-radius: 50%;
    /* padding: 10px; */
    margin: 150px auto 30px;
}

.presentation-mascot-container {
    white-space: nowrap;
    margin: 100px 0;
    width: fit-content;
}

.mascot-presentation-container {
    display: inline-block;
    width: 50%;
}

.presentation-container {
    display: inline-block;
    width: 50%;
}

.presentation {
    width: 100%;
    display: inline-block;
}

.presentation-center {
    width: 50%;
    margin: 0 auto;
}

.mascot-presentation {
    width: 50%;
    /* padding-bottom: 50%; */
}

/* /* /////////////////////// SUBSCRIPTION //////////////////////////////////*/

.subscribe-container {
    margin: 150px auto;
    padding: 10%;
    width: fit-content;
    background: url("./../Components/Media/beehive-opacity50.jpg");
    /* background-position: top ; */
    background-size: cover;
}

.subscribe-container-title {
    width: 100%;
    background-color: rgba(60, 116, 52, .4);
    border-radius: 50%;
    padding: 10px;
    margin: 0 auto 30px;
}

.subscribe-container h3 {
    font-size: 3rem;
    color: rgba(255, 215, 0, .9);
    margin: 50px auto;
}

.mascot-subscribe-welcome {
    width: 50px;
}


/* ////////// >= 750 PIXELS //////////*/

.beehive-home-750 {
    width: 90%;
    margin: 5px auto 10px;
    border-radius: 2%;
}

.logo-home-container-750 {
    width: 100%;
    background-color: rgba(255, 215, 0, .3);
    border-radius: 5%;
    padding: .5% 0;
    margin: 30px 0 100px;
}

.logo-home-750 {
    width: 55%;
}

/* //////////////////////Welcome News //////////////////*/

.welcome-news-feed {
    width: fit-content;
    margin: 10px auto;
    white-space: nowrap;
}

.mascot-welcome-container-750 {
    display: block;
    width: fit-content;
    margin: 0 auto;
}

.welcome-message-home {
    font-style: italic;
    font-weight: 600;
    background-color: rgba(222, 224, 227, .75);
    color: #000;
    width: fit-content;
    /* padding: 10px; */
    margin: 0 auto;
}

.mascot-welcome-750 {
    width: 20vw;
    display: inline-block;
}

.slide-show-home-container-750 {
    width: fit-content;
    display: block;
    margin: 10px auto;
}

.slide-show-home-image-750 {
    width: 50vw;
}

/* //////////////////////////////////////////////////////////////////// */


/* ///////////////// EXPLORE - PRESENTATIONS //////////////// */


.explore-container-title-750 h1 {
    font-size: 1rem;
    color: rgba(222, 224, 227, .75);
    /* margin: 50px auto; */
    
}

.explore-container-title-750 {
    width: 100%;
    background-color: rgba(60, 116, 52, .4);
    border-radius: 5%;
    /* padding: 10px; */
    margin: 70px auto 0;
}

.presentation-mascot-container-750 {
    white-space: nowrap;
    margin: 90px auto;
    width: fit-content;
}

.mascot-presentation-container-750 {
    display: block;
    width: 50%;
    margin: 5px auto;
}

.presentation-container-750 {
    display: block;
    width: 80%;
    margin: 5px auto;
}

.presentation {
    width: 100%;
    display: inline-block;
}

.presentation-center-750 {
    width: 80%;
    margin: 0 auto;
}

.presentation-center-ad {
    width: 30%;
    display: block;
    min-width: 140px;
    margin: 30px auto;
}

.mascot-presentation {
    width: 50%;
    /* padding-bottom: 50%; */
}

/* /* /////////////////////// SUBSCRIPTION //////////////////////////////////*/

.subscribe-container-750 {
    margin: 150px auto;
    padding: 10%;
    width: fit-content;
    background: url("./../Components/Media/beehive-opacity50.jpg");
    /* background-position: top ; */
    background-size: cover;
}

.subscribe-container-title-750 {
    width: 100%;
    background-color: rgba(60, 116, 52, .4);
    border-radius: 50%;
    padding: 10px;
    margin: 0 auto 30px;
}

.subscribe-container-750 h3 {
    font-size: 1rem;
    color: rgba(255, 215, 0, .9);
    margin: 50px auto;
}

.mascot-subscribe-welcome {
    width: 50px;
}
