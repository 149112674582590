.newspaper-articles {
    position: relative;
    /* border-radius: 10px; */
    width: 220px;
    height: 300px;

    --max-width: 90vw;
    --max-height: var(--max-width * 1.36);
    max-width: var(--max-width);
    max-height: var(--max-height);

    flex: 1 1 auto;
    background-color: whitesmoke;
    -webkit-box-shadow: 1px 1px 12px #000;
    box-shadow: 1px 1px 12px #000;
    -webkit-transform: preserve-3d;
    -ms-transform: preserve-3d;
    transform: preserve-3d;
    -webkit-perspective: 2000px;
    perspective: 2000px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #000;
    margin: 0 auto;

    background: url("./../../Media/BBS-news/BBS-newspaper-inside-background.png");
    background-size: cover;
    background-position: top;
}

.newspaper-articles-inside {
    width: 77.3%;
    margin: 0 auto;
    position: absolute;
    top: 50px;
}

.newspaper-articles-image {
    background: url("./../../Media/BBS-news/bee-reading-newspaper.jpg");
    background-size: cover;
    background-position: top;

    width: 76.5%;
    min-height: 130px;
    margin: 0 auto;
}

.newspaper-articles-image img {
    width: 100%;
}

.newspaper-articles-inside p {
    font-size: 20px;
    font-weight: bolder;
    margin: 10px auto;
    font-family: 'Flatory', serif;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
  }