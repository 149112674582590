.footer-container {
    position: fixed;
    bottom: 0;
}

.new_footer_area {
    background: #fbfbfd;
    position: relative;
    bottom: 0;
    width: 100%;
}


.new_footer_top {
    padding: 120px 0px 270px;
    position: relative;
      overflow-x: hidden;
}
.new_footer_area .footer_bottom {
    padding-top: 5px;
    padding-bottom: 50px;
}
.footer_bottom {
    margin: 30px auto 0;
    font-size: 14px;
    font-weight: 300;
    color: #7f88a6;
    padding: 27px 0px;
}
.new_footer_top .company_widget p {
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    color: #6a7695;
    margin-bottom: 20px;
}
.new_footer_top .company_widget .f_subscribe_two .btn_get {
    border-width: 1px;
    margin-top: 20px;
}
.btn_get_two:hover {
    background: transparent;
    color: #ffc107;
}
.btn_get:hover {
    color: #fff;
    background: #ffc107;
    border-color: #ffc107;
    -webkit-box-shadow: none;
    box-shadow: none;
}
a:hover, a:focus, .btn:hover, .btn:focus, button:hover, button:focus {
    text-decoration: none;
    outline: none;
}



.new_footer_top .f_widget.about-widget .f_list li a:hover {
    color: #ffc107;
}
.new_footer_top .f_widget.about-widget .f_list li {
    margin-bottom: 11px;
}
.f_widget.about-widget .f_list li:last-child {
    margin-bottom: 0px;
}
.f_widget.about-widget .f_list li {
    margin-bottom: 15px;
}
.f_widget.about-widget .f_list {
    margin-bottom: 0px;
}
.new_footer_top .f_social_icon a {
    width: 44px;
    height: 44px;
    line-height: 43px;
    background: transparent;
    border: 1px solid #e2e2eb;
    font-size: 24px;
}
.f_social_icon a {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    font-size: 14px;
    line-height: 45px;
    color: #858da8;
    display: inline-block;
    background: #ebeef5;
    text-align: center;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}
.ti-facebook:before {
    content: "\e741";
}
.ti-twitter-alt:before {
    content: "\e74b";
}
.ti-vimeo-alt:before {
    content: "\e74a";
}
.ti-pinterest:before {
    content: "\e731";
}

.btn_get_two {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #ffc107;
    border-color: #ffc107;
    color: #fff;
}

.btn_get_two:hover {
    background: transparent;
    color: #ffc107;
}

.new_footer_top .f_social_icon a:hover {
    background: #ffc107;
    border-color: #ffc107;
  color:white;
}
.new_footer_top .f_social_icon a + a {
    margin-left: 4px;
}
.new_footer_top .f-title {
    margin-bottom: 30px;
    color: #263b5e;
}
.f_600 {
    font-weight: 600;
}
.f_size_18 {
    font-size: 18px;
}
.new_footer_area h1, h2, h3, h4, h5, h6 {
    color: #4b505e;
}
.new_footer_top .f_widget.about-widget .f_list li a {
    color: #6a7695;
}


.new_footer_top .footer_bg {
    position: absolute;
    bottom: 0;
    background: url("./../Media/Footer/footer_bg.png") no-repeat scroll center 0;
    width: 100%;
    height: 266px;
}

.new_footer_top .footer_bg .footer_bg_one {
    background: url("./../Media/Footer/car.gif") no-repeat center center;
    width: 330px;
    height: 105px;
  background-size:100%;
    position: absolute;
    bottom: 0;
    left: 30%;
    -webkit-animation: myfirst 22s linear infinite;
    animation: myfirst 22s linear infinite;
}

.new_footer_top .footer_bg .footer_bg_two {
    background: url("./../Media/Footer/cyclist.gif") no-repeat center center;
    width: 88px;
    height: 100px;
  background-size:100%;
    bottom: 0;
    left: 38%;
    position: absolute;
    -webkit-animation: myfirst 30s linear infinite;
    animation: myfirst 30s linear infinite;
}

.new_footer_top .footer_bg .footer_bg_three {
    background: url("./../Media/Footer/summer-run.gif") no-repeat center center;
    width: 88px;
    height: 100px;
    background-size:100%;
    bottom: 0;
    left: 38%;
    position: absolute;
    -webkit-animation: myfirst 15s linear infinite;
    animation: myfirst 15s linear infinite;
}

.new_footer_top .footer_bg .footer_bg_four {
    /* background: url("./../Media/Footer/beea-chasing-bee.gif") no-repeat center center; */
    width: 90px;
    height: 200px;
    background-size:100%;
    bottom: 0;
    left: 38%;
    position: absolute;
    -webkit-animation: myfirst 40s linear infinite;
    animation: myfirst 40s linear infinite;
}

.footer_bg_four img {
    width: 50%;
}

.runningBeea {
    top: 0;
    left: -30%;
    position: absolute;
    -webkit-animation: flying 40s linear infinite;
    animation: flying 2s linear infinite;
}

.runningHoneybee {
    position: absolute;
    top: 20px;
}

@-moz-keyframes myfirst {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}

@-webkit-keyframes myfirst {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}

@keyframes myfirst {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}

@-moz-keyframes flying {
    0% {
      top: 0%;
    }
    50% {
        top: 10%;
    }
    100% {
      top: 0%;
    }
  }
  
  @-webkit-keyframes flying {
    0% {
      top: 0%;
    }
    50% {
        top: 10%;
    }
    100% {
      top: 0%;
    }
  }
  
  @keyframes flying {
    0% {
      top: 0%;
    }
    50% {
        top: 10%;
    }
    100% {
      top: 0%;
    }
  }

/*///////////////*/

.sub-message-initial {
    display: none;
    position: relative;
}

.sucess-message {
    display: block;
    position: relative;
    color: #11e045 !important;

}

.error-message {
    display: block;
    position: relative;
    color: #fa0404 !important;

}