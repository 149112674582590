.cover-image-gallery-container {
    margin: 70px;
    height: 150px;    
    min-height: 150px;
}

.cover-image-gallery-container:hover {
  font-size: 1.2rem;
}

.cover-title-gallery {
    /* font-size: 1.3rem; */
    overflow: visible;
    text-overflow: inherit;
    white-space: nowrap;
    border-radius: 20%;
}


.cover-image-gallery {
    height: 150px;
    border-radius: 5%;
    margin: auto;
    transition: transform 0.3s ease-in-out;
    
}


.cover-title-gallery {
    margin: 20px 10px;
    color: rgba(0, 0, 0, .7) ;
    background-color: rgba(255, 215, 0, .9);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
    font-weight: 700;
    /* font-size: 1.3rem; */
    transition: font-size 0.3s ease-in-out;
}


.activeImage img{
    animation: divPosition .3 forwards; 
}

@keyframes divPosition {
  from {
  }
  to {
    top: 7%;
    left: 30%;
    /* transform: translate(50%, 50%); */
    width: 40%;
    height: auto;
    margin: auto;
    position: fixed;
    z-index: 1000;
  }
}

.activeImage h3 {
  opacity: 0;
}

.activeImage::backdrop {
  background: rgba(0, 0, 0, 1);
}


  /* .cover-image-gallery:active img {
    animation: imageExpansion .3 forwards; 
  }

@keyframes imageExpansion {
  from {
  }
  to {
    width: 100%;
    margin: 0 auto;
    position: fixed;
    z-index: 100;
    opacity: 1;
  }
} */