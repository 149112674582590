.background-image-gallery {
    background: url("./../../Components/Media/gallery.jpg");
    background-position: top;
    background-size: cover;
    background-repeat: repeat;
    width: 100%;
    min-height: 1262px;
    padding: 100px 0;
    z-index: 1;

    opacity: 0;
    animation: fadeInBackground .45s forwards;
}

@keyframes fadeInBackground {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}


.background-image-gallery-all {
    background: url("./../../Components/Media/gallery.jpg");
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 1262px;
    background-repeat: repeat;
    padding-top: 30px;
    z-index: 1;
}