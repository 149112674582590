form {
    margin: 20px auto;
}

input {
    border-radius: 35%;
    padding: 0.375rem 0.75rem;
    border: none;
    text-decoration: none;
}

input:focus {
  outline: none;
}

.button-subscribe {
    display: inline-block;
    font-weight: 500;
    background-color: rgba(255, 215, 0, 1);
    text-align: center;
    vertical-align: middle;
    user-select: none;
    /* background-color: transparent; */
    border: none;
    margin: 0 10px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    /* line-height: 1.5; */
    border-radius: 35%;
    text-decoration: none;
    /* transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; */
  }
  
 .button-subscribe:hover {
    /* font-weight: 600; */
    text-decoration: none;
    transform: scale(1.1);
  }
  
 .button-subscribe:focus,.button-subscribe.focus {
    /* outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); */
    box-shadow: none;
    outline: none;
    border: none;
  }
  
 /* .button-subscribe.disabled,.button-subscribe:disabled {
    opacity: 0.65;
  } */
  
  .button-subscribe:not(:disabled):not(.disabled) {
    cursor: pointer;
  }


.hourglass {
  display: inline-block;
  height: 40px;
}

.hidden {
  opacity: 0;
}

  /*//////////////*/

.sub-message-initial {
    display: none;
    position: relative;
}

.success-message {
  display: block;
  position: relative;
  color: #11e045 !important;
  background-color: rgba(0, 0, 0, .4) !important;
  width: fit-content;
  margin: 0 auto;

}

.error-message {
  display: block;
  position: relative;
  color: #fa0404 !important;
  background-color: rgba(0, 0, 0, .5) !important;
  width: fit-content;
  margin: 0 auto;
}
  