.background-image-rules {
    background: url("./../../Components/Media/Contest/rules.png");
    background-position: center;
    background-size: cover;
    background-repeat: repeat;

    width: 100%;
    height: 0;
    padding-top: 100%;
    position: absolute;
    margin: 0 auto;
    z-index: 1;

    opacity: 0;
    animation: fadeInBackground .45s forwards;
}

@keyframes fadeInBackground {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}



.background-image-rules-filling {
    background: url("./../../Components/Media/rules.jpg");
    background-size: cover;
    background-repeat: repeat;

    width: 100%;
    height: 0;
    padding-top: 100%;
    position: absolute;
    margin: 0 auto;
    z-index: -1;

    opacity: .5;
}

.rules-container {
    z-index: 2;
}


.rules-container h1 {
    color: black;
    background-color: rgba(222, 222, 222, 0.7);
    width: fit-content;
    margin: 10px auto;
    padding: 10px;
    border-radius: 50%;
}

.rules-container h3 {
    color: rgba(222, 222, 222, .8);
}

.rules-container p {
    color: rgb(222, 222, 222);
    background-color: rgba(0, 0, 0, .7);
    width: fit-content;
    max-width: 500px;
    margin: 20px auto;
    padding: 5px;
    border-radius: 5%;
}

.rules-rules {
    width: fit-content;
    margin: 20px auto;
}

.rules-theme {
    margin: 100px auto;
}


.rules-theme-images img {
    width: 200px;
    margin: 0 5px;
    display: inline-block;
}

.rules-more-details p {
    margin-bottom: 90px;
}

.rules-details-punchline {
    background-color: rgba(222, 224, 227, .75);
    color: rgba(0, 0, 0, .9);
    font-style: italic;
    font-weight: 600;
    width: fit-content;
    padding: 10px;
    border-radius: 50%;

    margin: 0 auto;
}

