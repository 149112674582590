.weaky-book-article-main-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;

    margin: 20px auto;
    font-family: "eb garamond";
}

.weaky-book-article-container1 {
    width: 250px;
    height: 250px;

    border-radius: 10px;

    --max-width: 90vw;
    --max-height: var(--max-width);

    max-width: var(--max-width);
    max-height: var(--max-height);

    display: inline-block;
    /* max-width: 500px;
    max-height: 250px; */
    
    margin: 0 auto;
    position: relative;
}


.weaky-book-article-container2 {
    width: 250px;
    height: 250px;

    border-radius: 0 10px 10px 0;

    --max-width: 90vw;
    --max-height: var(--max-width);

    max-width: var(--max-width);
    max-height: var(--max-height);

    display: inline-block;
    /* max-width: 500px;
    max-height: 250px; */
    
    margin: 0 auto;
    background: #000;
    position: relative;
}

.weaky-book-article-container1 img, .weaky-book-article-container2 img {
    width: 100%;
    height: 100%;
    border-radius: 10px 0 0 10px;
}

.weaky-book-article-inside-container {
    position: relative;
    margin-top: 1.5rem;
    height: fit-content;
}

.weaky-cover-article-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 3.5rem;
    /* color: #FFB800; */
    color: #dedede;
}


@media screen and (max-width: 230px) {
    .weaky-cover-inside-description {
        opacity: 0;
    }

    .weaky-honeycomb {
        font-size: 1%;
    }

    .weaky-honeycomb:hover {
        background-color: white;
    }

}

.weaky-article-honeycomb {
    width: 30%; /* Adjust according to your needs */
    height: 10%; /* Adjust according to your needs */
    background-color: rgba(60, 116, 52, .9); /* Or any other color you prefer */
    clip-path: polygon(
        10% 0,
        90% 0,
        100% 50%,
        90% 100%,
        10% 100%,
        0% 50%
        );
    font-size: .8rem;
    font-weight: 800;

    padding-top: .2rem;
    /* margin: 0 auto; */
    position: absolute;
    bottom: 10%;
    left: 36%;
}

.weaky-honeycomb:hover {
    background-color: rgba(60, 116, 52, 1);
    transform: scale(1.2rem);
    color: white;
}

