.background-image-shop {
    background: url("./../Components/Media/beexshop.png");
    background-position: top;
    background-size: cover;
    width: 100%;
    min-height: 1262px;
    padding: 100px 0;
    background-repeat: repeat;
    z-index: 1;

    opacity: 0;
    animation: fadeInBackground .45s forwards;
}

.shop-title {
    color: #fff;
    font-size: 3rem;
    font-weight: 700;
}

.shop-title-2 {
    color: rgba(255, 184, 0, 1);
    font-size: 3rem;
    font-weight: 700;
}

.shop-detail {
    font-size: 6rem;
    font-weight: 700;
    /* background-color: none !important; */
    color: rgba(37, 228, 7, 0.8);
}